import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

import Layout, { Section } from "../components/layout"
import SEO from "../components/seo"
import Columns, { Column } from "../components/Columns"
import SilbatoMobileAppImage from "../components/images/SilbatoMobileAppImage"
import Button from "../components/Button"
import FeatureAlarmTypes from "../components/images/landing/FeatureAlarmTypes"
import FeatureAlert from "../components/images/landing/FeatureAlert"
import FeatureCommunicate from "../components/images/landing/FeatureCommunicate"
import FeatureLog from "../components/images/landing/FeatureLog"
import FreeQuoteCTA from "../components/FreeQuoteCTA"
import InstallerBanner from "../components/InstallerBanner"
import PageFeatureHeader from "../components/PageFeatureHeader"
import MobileAppFeatures from "../components/MobileAppFeatures"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "title" })} />

      <Section>
        <PageFeatureHeader image={<SilbatoMobileAppImage />}>
          <h2>
            <FormattedMessage id="landing.title" />
          </h2>
          <p>
            <FormattedMessage id="landing.description" />
          </p>
          <Button
            to={intl.formatMessage({ id: "pages.quote" })}
            className="get-free-quote landing"
          >
            {intl.formatMessage({ id: "landing.getQuote" })}
          </Button>
        </PageFeatureHeader>
        {/* TODO: Scroll down Arrow */}
      </Section>

      <Section>
        <h4 className="text-center">
          <FormattedMessage id="landing.numberOne" />
        </h4>
      </Section>

      <MobileAppFeatures />

      <Section>
        <FreeQuoteCTA />
      </Section>

      <Section>
        <InstallerBanner />
      </Section>
    </Layout>
  )
}

export default IndexPage

import React from "react"
import styled from "styled-components"

import Columns, { Column } from "./Columns"
import Center from "./Center"
import { MAX_MOBILE_WIDTH } from "../constants/sizes"

interface IProps {
  image: any
}

const ContentWrapper = styled.div`
  margin-top: 5rem;

  @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    margin-top: 0;
  }
`

export default function PageFeatureHeader({
  image,
  children,
}: React.PropsWithChildren<IProps>) {
  return (
    <Columns noPadding>
      <Column>{image}</Column>
      <Column>
        <Center onlyMobile>
          <ContentWrapper>{children}</ContentWrapper>
        </Center>
      </Column>
    </Columns>
  )
}
